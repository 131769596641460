import React from "react"
import { graphql, useStaticQuery, Link } from "gatsby"

import styles from "./index.module.scss"
import { TwitterTimelineEmbed } from "react-twitter-embed"

const Footer = () => {
  const data = useStaticQuery(
    graphql`
      query {
        allMdx(filter: { frontmatter: { type: { eq: null } } }) {
          group(field: frontmatter___tags) {
            fieldValue
            totalCount
          }
        }
        allTagJson {
          nodes {
            name
            slug
          }
        }
      }
    `
  )
  const { group: ts } = data.allMdx
  const { nodes: tags_slug } = data.allTagJson
  let tags = Array.from(ts)
  tags.sort((a, b) => {
    return b.totalCount - a.totalCount
  })
  tags = tags.slice(0, 100)
  const now = new Date()
  const year = now.getFullYear()

  return (
    <footer className={styles.footer}>
      <div className="uk-margin-left uk-margin-right" uk-grid="true">
        <div className="uk-width-1-2@m uk-visible@m uk-text-center">
          <h3 className={styles.title}>Tags</h3>
          <div className={styles.tags}>
            {tags.map(tag => {
              let link = "/"
              for (let i = 0; i < tags_slug.length; i++) {
                if (tags_slug[i].name === tag.fieldValue) {
                  link = link + "tag/" + tags_slug[i].slug
                  break
                }
              }
              return (
                <Link
                  className="uk-label uk-margin-small-right uk-text-truncate"
                  to={link}
                >
                  {tag.fieldValue}({tag.totalCount})
                </Link>
              )
            })}
          </div>
        </div>
        <div className="uk-width-1-2@m uk-visible@m">
          <div className="uk-text-center">
            <h3 className={styles.title}>Twitter</h3>
          </div>
          <div className="uk-flex uk-flex-center" title="twitterTL">
            <TwitterTimelineEmbed
              sourceType="profile"
              screenName="jackjasonb"
              theme="dark"
              lang="ja"
              noFooter={true}
              // autoHeight={true}
              options={{
                width: 500,
                height: 500,
                tweetLimit: 2,
              }}
            />
          </div>
        </div>
      </div>
      <div
        className="uk-flex uk-flex-center"
        style={{ color: "#f9f9fa" }}
        uk-grid="true"
      >
        じゃっくそん's Place ©2017-{year} じゃっくそん
      </div>
    </footer>
  )
}

export default Footer
