import React from 'react'
import Helmet from 'react-helmet'
import { graphql, useStaticQuery } from 'gatsby'

export default function Meta({ isRoot, title, imageUrl, description }) {
  const data = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            siteUrl
            twitter
            blogImageUrl
          }
        }
      }
    `
  )
  const type = isRoot ? 'website' : 'article'
  const blogUrl = data.site.siteMetadata.siteUrl
  const blogTitle = data.site.siteMetadata.title
  const twitter = data.site.siteMetadata.twitter
  var blogDescription = data.site.siteMetadata.description
  if (description) {
    blogDescription = description
  }
  var blogImageUrl = data.site.siteMetadata.blogImageUrl
  if (imageUrl) {
    blogImageUrl = blogUrl + imageUrl
  }
  return (
    <Helmet>
      <meta name="image" content={blogImageUrl} />
      <meta name="description" content={blogDescription} />
      <meta property="og:title" content={title || blogTitle} />
      <meta property="og:description" content={blogDescription} />
      <meta property="og:url" content={blogUrl} />
      <meta property="og:type" content={type} />
      <meta property="og:site_name" content={blogTitle} />
      <meta property="og:image" content={blogImageUrl} />
      <meta name="twitter:card" content="summary" />
      <meta name="twitter:site" content={twitter} />
      <html lang="ja" />
    </Helmet>
  )
}
