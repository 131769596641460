import React from "react"

const MySNS = props => {
  return (
    <div>
      <a href="https://twitter.com/jackjasonb" aria-label="Twitter">
        <span uk-icon="twitter" className="uk-margin-small-right" />
      </a>
      <a href="https://github.com/jackjasonb" aria-label="Github">
        <span className="uk-margin-small-right" uk-icon="github" />
      </a>
      <a
        href="https://www.youtube.com/channel/UCbzUTlavtcagwLBd3gSonNQ"
        aria-label="Youtube"
      >
        <span className="uk-margin-small-right" uk-icon="youtube" />
      </a>
      <a
        href="https://www.nicovideo.jp/user/66221727"
        aria-label="ニコニコ動画"
      >
        <span className="uk-margin-small-right" uk-icon="video-camera" />
      </a>
      <a href="/rss.xml" aria-label="RSS">
        <span uk-icon="rss" className="uk-margin-small-right" />
      </a>
      <a
        href="https://feedly.com/i/subscription/feed%2Fhttps%3A%2F%2Fjackjasonb.com%2Frss.xml"
        aria-label="Feedly RSS"
      >
        <span uk-icon="bookmark" className="uk-margin-small-right" />
      </a>
    </div>
  )
}

export default MySNS
