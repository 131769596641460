import React from "react"
import { Link, graphql, useStaticQuery } from "gatsby"
import BackgroundImage from "gatsby-background-image"

import Navbar from "../navbar"
import MySNS from "../mySNS"

import styles from "./index.module.scss"

const Header = props => {
  const query = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
          }
        }
        file(name: { eq: "g_4k" }) {
          childImageSharp {
            fluid(maxWidth: 1920, webpQuality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `
  )
  const siteTitle = query.site.siteMetadata.title
  const {
    title = siteTitle,
    description = query.site.siteMetadata.description,
    loc = "/",
    image = query.file.childImageSharp.fluid,
  } = props

  let headerText = ""
  if (loc === "/") {
    // nocard style
    headerText = (
      <div id={styles.header_text}>
        <Link to={loc}>
          <h1>{title}</h1>
        </Link>
        <div id={styles.description}>{description}</div>
      </div>
    )
  } else {
    // card style
    headerText = (
      <div
        className="uk-card uk-box-shadow-xlarge"
        id={styles.header_text_card}
      >
        <div
          className="uk-card-header uk-text-left uk-margin-remove uk-grid-small"
          uk-grid="true"
        >
          <Link to="/" id="title" style={{ margin: "3px" }}>
            {siteTitle}
          </Link>
        </div>
        <div className="uk-card-body uk-padding-small">
          <h1 className="uk-text-center">{title}</h1>
        </div>
        <hr className="uk-margin-remove" />
        <div
          className="uk-card-footer uk-padding-small uk-text-left uk-margin-remove uk-grid-small"
          id={styles.description}
          uk-grid="true"
        >
          {description}
        </div>
      </div>
    )
  }

  return (
    <header>
      <Navbar />
      <BackgroundImage
        className="uk-background-top-center uk-background-cover uk-panel uk-flex uk-flex-center uk-flex-middle"
        Tag="div"
        fluid={image}
        style={{ height: "400px" }}
      >
        <div className="uk-text-center uk-position-center">{headerText}</div>
        <div className="uk-margin-auto-top uk-hidden@m">
          <MySNS />
        </div>
      </BackgroundImage>
    </header>
  )
}

export default Header
