import React from "react"
import { graphql, useStaticQuery, Link } from "gatsby"

const CategoryList = () => {
  const data = useStaticQuery(
    graphql`
      query {
        allCategoryJson {
          nodes {
            name
            slug
          }
        }
        allMdx(filter: { frontmatter: { type: { eq: null } } }) {
          group(field: frontmatter___categories) {
            fieldValue
            totalCount
          }
        }
      }
    `
  )
  const { group: cats } = data.allMdx
  const { nodes: cats_slug } = data.allCategoryJson

  let categories = Array.from(cats)
  categories.sort((a, b) => {
    return a.fieldValue > b.fieldValue
  })

  return (
    <section style={{ padding: 0 }}>
      {categories.map(cat => {
        let link = "/"
        for (let i = 0; i < cats_slug.length; i++) {
          if (cats_slug[i].name === cat.fieldValue) {
            link = link + "category/" + cats_slug[i].slug
            break
          }
        }
        return (
          <div style={{ fontSize: "90%" }}>
            <span uk-icon="folder" style={{ marginRight: "5px" }} />
            <Link to={link}>
              {cat.fieldValue}({cat.totalCount})
            </Link>
          </div>
        )
      })}
    </section>
  )
}

export default CategoryList
