import React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"

import styles from "./index.module.scss"
import CategoryList from "../categoryList"
import MySNS from "../mySNS"

const Navbar = () => {
  const data = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
          }
        }
      }
    `
  )
  const title = data.site.siteMetadata.title
  return (
    <div
      uk-sticky="sel-target: .uk-navbar-container; cls-active: uk-navbar-sticky"
      style={{ position: "relative", zIndex: "980" }}
      className="uk-hidden@m"
    >
      <nav className="uk-navbar-container uk-box-shadow-small" uk-navbar="true">
        <div className="uk-navbar-left">
          <a href="/" className="uk-navbar-item uk-logo" id={styles.logo}>
            {title}
          </a>
        </div>
        <div className="uk-navbar-right">
          <button
            className="uk-button uk-button-default uk-margin-small-right"
            type="button"
            uk-toggle="target: #offcanvas-usage"
            id={styles.menu}
            aria-label="Menu"
          >
            <span uk-icon="icon: menu; ratio: 2" className={styles.menu}></span>
          </button>
          <div id="offcanvas-usage" uk-offcanvas="true">
            <div className="uk-offcanvas-bar">
              <button
                className="uk-offcanvas-close"
                type="button"
                uk-close="true"
              >
                {" "}
              </button>
              <h3 className="uk-heading-line uk-text-center">
                <span>CATEGORY</span>
              </h3>
              <CategoryList />
              <section className="pages">
                <h3 className="uk-heading-line uk-text-center">
                  <span>PAGES</span>
                </h3>
                <div>
                  <span uk-icon="file-text" style={{ marginRight: "3px" }} />
                  <Link to="/football-calendar/">Football Calendar</Link>
                </div>
                <div>
                  <span uk-icon="file-text" style={{ marginRight: "3px" }} />
                  <Link to="/2017/06/26/102/">このすば 特典小説</Link>
                </div>
                <div>
                  <span uk-icon="file-text" style={{ marginRight: "3px" }} />
                  <Link to="/dq7-tas/">DQ7 TAS まとめ</Link>
                </div>
              </section>
              <h3 className="uk-heading-line uk-text-center">
                <span>SNS</span>
              </h3>
              <MySNS />
            </div>
          </div>
        </div>
      </nav>
    </div>
  )
}

export default Navbar
