import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"

import CategoryList from "../categoryList"
import MySNS from "../mySNS"

export default class Sidebar extends React.Component {
  render() {
    const Sidebar = styled.aside`
      font-size: 90%;
      padding: 8px 0px;
      span p {
        margin-left: 1em !important;
      }
      h3 {
        margin-top: 1em !important;
      }
      .uk-heading-line > span {
        ::after,
        ::before {
          border-bottom-color: #bbbbbb;
        }
      }
    `
    return (
      <Sidebar>
        <section className="profile">
          <h3 className="uk-heading-line uk-text-center">
            <span>PROFILE</span>
          </h3>
          <div style={{ textAlign: "center" }}>
            <img
              className="uk-border-circle"
              src="/profile.jpeg"
              width="75"
              height="75"
              alt="profile"
            />
          </div>
          <p>
            <strong>Name</strong>
            {" : "}
          </p>
          <p>じゃっくそん</p>
          <strong>Recent Work</strong> :
          <ul style={{ listStyle: "none", paddingLeft: "0" }}>
            <li>
              <a href="https://youtube.com/playlist?list=PL_3HTUlsaxZ1XpcdK57UEgmXhXbndH_5K">
                DQ7 TAS
              </a>
            </li>
            <li>Python</li>
            <li>Gatsby.js</li>
          </ul>
          <p>
            <strong>Favorite</strong> :
          </p>
          <ul style={{ listStyle: "none", paddingLeft: "0" }}>
            <li>この素晴らしい世界に祝福を！</li>
            <li>Re:ゼロから始める異世界生活</li>
            <li>FC東京</li>
            <li>映画"ジェイソン・ボーン"シリーズ</li>
          </ul>
          <p>
            <strong>Mail</strong>
            {" : "}
          </p>
          <p>jackjasonb at gmail.com</p>
          <MySNS />
        </section>
        <section className="category">
          <h3 className="uk-heading-line uk-text-center">
            <span>CATEGORY</span>
          </h3>
          <CategoryList />
        </section>
        <section className="pages">
          <h3 className="uk-heading-line uk-text-center">
            <span>PAGES</span>
          </h3>
          <div>
            <span uk-icon="file-text" style={{ marginRight: "3px" }} />
            <Link to="/football-calendar/">Football Calendar</Link>
          </div>
          <div>
            <span uk-icon="file-text" style={{ marginRight: "3px" }} />
            <Link to="/2017/06/26/102/">このすば 特典小説</Link>
          </div>
          <div>
            <span uk-icon="file-text" style={{ marginRight: "3px" }} />
            <Link to="/dq7-tas/">DQ7 TAS まとめ</Link>
          </div>
        </section>
      </Sidebar>
    )
  }
}
