import React from "react"

import Sidebar from "../sidebar"
import Header from "../header"
import Footer from "../footer"

import "./layout.scss"

class TemplateWrapper extends React.Component {
  componentDidMount() {
    try {
      this.UIkit = require("uikit/dist/js/uikit")
      this.Icons = require("uikit/dist/js/uikit-icons")
      this.UIkit.use(this.Icons)
    } catch (e) {
      console.error(e)
    }
  }
  render() {
    const { children, header = <Header /> } = this.props

    return (
      <div>
        {header}
        <article className="uk-padding-small">
          <div className="uk-grid-divider" uk-grid="true">
            <div className="uk-width-1-4 uk-visible@m">
              <Sidebar />
            </div>
            <div className="uk-width-expand">{children}</div>
          </div>
        </article>
        <Footer />
      </div>
    )
  }
}

export default TemplateWrapper
